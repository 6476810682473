import React from 'react';
import thumbnail from '../picture.jpg';
import '../App.css';
import Title from "../headertitle.js";
import Footer from "../footer.js";
import AudioPlayer from "./AudioPlayer.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

const Home = () => {
    return (
        <div className="App">
      <header className="App-main">
        <div className="App-image-wrapper">
        <img src={thumbnail} className="App-image" alt="logo" />
        </div>
        <div class="socialLinkContainer">
          <a href="https://open.spotify.com/artist/29aImfkgSWV98BbiGlr1p6?si=olfgu5EsQE-tIxdSZF6tzA" target="_blank" rel="noopener noreferrer" data-testid="social-link-SPOTIFY">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{fill: "black"}}>
              <g id="Logo" clip-path="url(#clip0_392_18661)">
                <path id="Vector" d="M10 0C4.48 0 0 4.48 0 10c0 5.532 4.48 10 10 10 5.532 0 10-4.48 10-10 .012-5.52-4.468-10-10-10Zm4.588 14.432a.621.621 0 0 1-.86.204c-2.354-1.434-5.305-1.757-8.794-.968a.618.618 0 1 1-.275-1.207c3.812-.872 7.085-.502 9.714 1.111.31.18.406.562.215.86Zm1.23-2.723a.79.79 0 0 1-1.075.262c-2.688-1.66-6.786-2.138-9.964-1.17a.773.773 0 1 1-.442-1.481c3.632-1.1 8.148-.574 11.242 1.325a.778.778 0 0 1 .24 1.064Zm.108-2.844C12.7 6.953 7.383 6.775 4.3 7.706a.942.942 0 0 1-1.17-.621.942.942 0 0 1 .62-1.171c3.537-1.075 9.415-.86 13.119 1.338a.933.933 0 0 1 .322 1.278.91.91 0 0 1-1.266.335Z" fill="#F5F3FD"></path>
              </g>
              <defs>
                <clipPath id="clip0_392_18661">
                  <path fill="#fff" d="M0 0h20v20H0z"></path>
                </clipPath>
              </defs>
            </svg>
          </a>
          <a href="https://www.instagram.com/bobbyflava/" target="_blank" rel="noopener noreferrer" data-testid="social-link-INSTAGRAM">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Logo" clip-path="url(#clip0_392_18663)">
                <g id="Vector" fill="#F5F3FD">
                  <path d="M10 1.802c2.67 0 2.986.01 4.04.058.975.045 1.505.207 1.857.344.467.182.8.398 1.15.748.35.35.567.683.748 1.15.137.353.3.882.344 1.857.048 1.054.059 1.37.059 4.04 0 2.671-.01 2.987-.059 4.042-.044.975-.207 1.504-.344 1.857-.181.466-.398.8-.748 1.15-.35.35-.683.566-1.15.747-.352.137-.882.3-1.857.345-1.054.048-1.37.058-4.04.058s-2.987-.01-4.041-.058c-.975-.045-1.505-.208-1.857-.345a3.102 3.102 0 0 1-1.15-.748 3.092 3.092 0 0 1-.748-1.15c-.137-.352-.3-.881-.344-1.856-.048-1.055-.058-1.371-.058-4.041 0-2.67.01-2.986.058-4.041.044-.975.207-1.504.344-1.857.182-.467.398-.8.748-1.15.35-.35.683-.567 1.15-.748.352-.137.882-.3 1.857-.344 1.054-.048 1.37-.058 4.04-.058ZM10 0C7.284 0 6.943.012 5.877.06 4.812.11 4.086.278 3.449.525A4.897 4.897 0 0 0 1.68 1.68a4.898 4.898 0 0 0-1.154 1.77C.278 4.086.11 4.813.06 5.877.012 6.943 0 7.284 0 10s.012 3.056.06 4.123c.049 1.064.218 1.79.465 2.427a4.897 4.897 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.77 1.154c.637.247 1.364.416 2.428.465 1.067.048 1.407.06 4.123.06s3.056-.012 4.123-.06c1.064-.049 1.791-.218 2.427-.465a4.897 4.897 0 0 0 1.771-1.154 4.902 4.902 0 0 0 1.154-1.77c.247-.637.416-1.364.465-2.428.048-1.067.06-1.407.06-4.123s-.012-3.057-.06-4.123c-.049-1.064-.218-1.792-.465-2.428a4.898 4.898 0 0 0-1.154-1.77 4.9 4.9 0 0 0-1.77-1.154C15.913.278 15.186.109 14.122.06 13.056.012 12.715 0 10 0Z">
                  </path>
                  <path d="M10 4.864a5.135 5.135 0 1 0 0 10.27 5.135 5.135 0 0 0 0-10.27Zm0 8.468a3.333 3.333 0 1 1 0-6.666 3.333 3.333 0 0 1 0 6.666ZM15.338 5.862a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4Z">
                  </path>
                </g>
              </g>
              <defs>
                <clipPath id="clip0_392_18663">
                  <path fill="#fff" d="M0 0h20v20H0z">
                  </path>
                </clipPath>
              </defs>
            </svg>
          </a>
          <a href="https://www.youtube.com/channel/UC7JciXONqKVn5Rk8pzDq06Q" target="_blank" rel="noopener noreferrer" data-testid="social-link-YOUTUBE"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM9.99953 4.93311C12.7942 4.93311 15.0672 7.20606 15.0672 10.0007C15.0672 12.7954 12.7942 15.0684 9.99953 15.0684C7.20484 15.0684 4.93189 12.7954 4.93189 10.0007C4.93189 7.20606 7.20484 4.93311 9.99953 4.93311ZM9.99953 4.44519C6.93069 4.44519 4.44397 6.93191 4.44397 10.0007C4.44397 13.0696 6.93069 15.5563 9.99953 15.5563C13.0684 15.5563 15.5551 13.0696 15.5551 10.0007C15.5551 6.93191 13.0684 4.44519 9.99953 4.44519ZM12.5923 9.90362L8.14789 12.2225V7.77802L12.5923 9.90362Z" fill="#F5F3FD"></path></svg></a>
          <a href="https://bobbyflava.com" target="_blank"><svg style={{fill: "#ffffff"}} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 512 512"><path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"/></svg></a>
          </div>
          
        <h1>
          Bobby Flava
        </h1>
        <p>New single out now on all platforms! 🐻</p>
        <Title />
        
      </header>
      {/* <AudioPlayer /> */}
      <Footer />
    </div>
    );
};


export default Home;