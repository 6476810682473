import React from 'react'

class Footer extends React.Component {
    render(){
      return <div class="copyright">
    <span>© Bobby Flava 2024</span>
</div>
    }
}

export default Footer